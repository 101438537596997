export enum SearchScope {
  all = 'all',
  articles = 'articles',
  persons = 'persons',
  companies = 'companies',
}

export enum SearchV3Scope {
  articles = 'articles',
  podcasts = 'podcasts',
  persons = 'persons',
  companies = 'companies',
}

export const SearchTranslationTab: Partial<Record<SearchScope, string>> = {
  articles: 'Artiklid',
  persons: 'Isikud',
  companies: 'Ettevõtted',
};
